<template>
  <div class="home">

    <section class="intro">
      <Navbar />

      <div class="intro__container container">
        <video ref="animation" class="intro__video" autoplay loop muted disablePictureInPicture>
          <source src="../assets/video/dotsfull.mp4" type="video/mp4">
        </video>
        <div class="row">
          <div class="col-xs-12 col-md-6">
            <div class="intro__txt">
              <h1 class="animated-fade fadeInUp">{{ $t('home.intro.title') }}</h1>
              <p class="animated-fade fadeInUp delay-2">{{ $t('home.intro.description') }}</p>
              <!-- <a v-scroll-to="'.services'" class="btn btn--primary btn--gooey mt-xs-40 animated-fade fadeInUp delay-4">
                <span class="btn-zone" @mousemove="hover($event)" @mouseleave="posX=50;posY=50;"></span>
                <div class="btn-container icon-after icon-arrow-down">
                  <span class="bubble" :style="{'left': posX-5+'%', 'top': posY-10+'%'}"></span>
                  <span>{{ $t('home.intro.cta') }}</span>
                </div>
              </a> -->
              <a v-scroll-to="'.services'" class="btn btn--primary icon-after icon-arrow-down mt-xs-40 animated-fade fadeInUp delay-4">
                {{ $t('home.intro.cta') }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="services pt-xs-60 pt-md-100 pb-xs-80 pb-md-140">
      <div class="container">
        <div class="row">
          <div class="col-xs-12">
            <h3 class="animated-fade" v-animate-onscroll="'fadeInUp'">{{ $t('home.services.subtitle') }}</h3>
            <h2 class="animated-fade" v-animate-onscroll="'fadeInUp'" v-html="$t('home.services.title')"></h2>
          </div>
        </div>

        <div class="row mt-xs-40 mt-md-80">
          <div class="col-md-4">
            <div class="services__item mb-xs-30 animated-fade" v-animate-onscroll="'fadeInUp'">
              <img class="mb-xs-20" style="width: 60px;" src="../assets/img/icon-network.png" alt="">
              <h4>{{ $t('home.services.service1.title') }}</h4>
              <p>{{ $t('home.services.service1.description') }}</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="services__item mb-xs-30 animated-fade" v-animate-onscroll="'fadeInUp delay-1'">
              <img class="mb-xs-20" style="width: 60px;" src="../assets/img/icon-network.png" alt="">
              <h4>{{ $t('home.services.service2.title') }}</h4>
              <p>{{ $t('home.services.service2.description') }}</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="services__item animated-fade" v-animate-onscroll="'fadeInUp delay-2'">
              <img class="mb-xs-20" style="width: 60px;" src="../assets/img/icon-network.png" alt="">
              <h4>{{ $t('home.services.service3.title') }}</h4>
              <p>{{ $t('home.services.service3.description') }}</p>
            </div>
          </div>
        </div>

        <div class="mt-xs-80 mt-md-140 animated-fade" v-animate-onscroll="'fadeInUp delay-3'">
          <div v-swiper:clientSwiper="clientSwiperOptions">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <img src="../assets/img/partners/microsoft.png" alt="Microsoft Partner">
              </div>
              <div class="swiper-slide">
                <img src="../assets/img/partners/veeam.png" alt="Veeam">
              </div>
              <div class="swiper-slide">
                <img src="../assets/img/partners/3CX.png" alt="3CX Partner">
              </div>
              <div class="swiper-slide">
                <img src="../assets/img/partners/fsecure.png" alt="3CX Partner">
              </div>
              <div class="swiper-slide">
                <img src="../assets/img/partners/doorbird.png" alt="Doorbird">
              </div>
              <div class="swiper-slide">
                <img src="../assets/img/partners/unifi.png" alt="Unifi">
              </div>
              <div class="swiper-slide">
                <img src="../assets/img/partners/combell.png" alt="Combell">
              </div>
              <div class="swiper-slide">
                <img src="../assets/img/partners/matterport.png" alt="Matterport">
              </div>
              <div class="swiper-slide">
                <img src="../assets/img/partners/backblaze.png" alt="Backblaze">
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="team pt-xs-180 animated-fade" v-animate-onscroll="{down: 'fadeIn'}">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-md-7 mt-md-20 hidden-xs hidden-sm">
            <div class="team__members mb-xs-40 mb-lg-0">
              <img class="team__members__member --jef" :class="{'--active': teamSwiperIndex === 0}" src="../assets/img/jef.png" alt="">
              <img class="team__members__member --boris" :class="{'--active': teamSwiperIndex === 1}" src="../assets/img/boris.png" alt="">
            </div>
          </div>
          <div class="col-xs-12 col-md-5">
            <h3 class="animated-fade" v-animate-onscroll="'fadeInUp'">{{ $t('home.about.subtitle') }}</h3>
            <h2 class="mb-xs-30 animated-fade" v-animate-onscroll="'fadeInUp delay-2'">{{ $t('home.about.title') }}</h2>
            <swiper class="animated-fade" v-animate-onscroll="'fadeInUp delay-4'" ref="teamSwiper" :options="teamSwiperOptions" @slideChange="teamSwiperIndex=teamSwiper.realIndex">
                <swiper-slide>
                  <div data-swiper-parallax-opacity="0">
                    <div class="team__member">Jef Vastenavondt</div>
                    <div class="team__jobtitle">{{ $t('home.about.jef.jobtitle') }}</div>
                    <p>{{ $t('home.about.jef.description') }}</p>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div data-swiper-parallax-opacity="0">
                    <div class="team__member">Boris Debusscher</div>
                    <div class="team__jobtitle">{{ $t('home.about.boris.jobtitle') }}</div>
                    <p>{{ $t('home.about.boris.description') }}</p>
                  </div>
                </swiper-slide>
            </swiper>
            <ul class="team__navigation animated-fade" v-animate-onscroll="{down: 'fadeIn delay-6'}">
              <li class="icon-before icon-chevron-left" v-on:click="teamSwiper.slidePrev()"></li>
              <li class="icon-before icon-chevron-right" v-on:click="teamSwiper.slideNext()"></li>
            </ul>
          </div>
          <div class="col-xs-12 col-md-7 visible-xs visible-sm animated-fade" v-animate-onscroll="'fadeInUp delay-8'">
            <div class="team__members">
              <img class="team__members__member --jef" :class="{'--active': teamSwiperIndex === 0}" src="../assets/img/jef.png" alt="">
              <img class="team__members__member --boris" :class="{'--active': teamSwiperIndex === 1}" src="../assets/img/boris.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="clients pt-xs-100 pb-xs-60 animated-fade" v-animate-onscroll="{down: 'fadeIn'}">
      <div class="container">
        <h3>{{ $t('home.clients.subtitle') }}</h3>
        <h2>{{ $t('home.clients.title') }}</h2>

        <div class="row mt-xs-40 animated-fade" v-animate-onscroll="'fadeInUp delay-2'">
          <div class="client col-xs-12 col-sm-6 col-md-4">
            <a href="https://headoffice.be/" target="_blank">
              <img src="../assets/img/clients/headoffice.png" alt="">
            </a>
          </div>
          <div class="client col-xs-12 col-sm-6 col-md-4">
            <a href="https://www.syus.be/" target="_blank">
              <img src="../assets/img/clients/syus.png" alt="">
            </a>
          </div>
          <div class="client col-xs-12 col-sm-6 col-md-4">
            <a href="https://a-venue.be/" target="_blank">
              <img src="../assets/img/clients/avenue.png" alt="">
            </a>
          </div>
          <div class="client col-xs-12 col-sm-6 col-md-4">
            <a href="http://www.timco-air.be/" target="_blank">
              <img src="../assets/img/clients/timco.png" alt="">
            </a>
          </div>
          <div class="client col-xs-12 col-sm-6 col-md-4">
            <a href="http://www.timco-air.be/" target="_blank">
              <img src="../assets/img/clients/sqills.png" alt="">
            </a>
          </div>
          <div class="client col-xs-12 col-sm-6 col-md-4">
            <a href="http://www.timco-air.be/" target="_blank">
              <img src="../assets/img/clients/nomad.png" alt="">
            </a>
          </div>
        </div>
      </div>
    </section>

    <section class="support pt-xs-30 pt-md-140 pb-xs-40 pb-md-100">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-md-5">
            <img class="mt-xs-15 animated-fade" src="../assets/img/teamviewer.png" v-animate-onscroll="{down: 'fadeInLeft'}">
          </div>
          <div class="col-xs-12 col-md-6 col-md-offset-1">
            <h3 class="animated-fade" v-animate-onscroll="'fadeInUp'">{{ $t('home.support.subtitle') }}</h3>
            <h2 class="mb-xs-30 animated-fade" v-animate-onscroll="'fadeInUp delay-2'" v-html="$t('home.support.title')"></h2>
            <p class="mb-xs-20 animated-fade" v-animate-onscroll="'fadeInUp delay-4'" v-html="$t('home.support.description')"></p>
            <div>
              <a class="btn btn--primary icon-before icon-apple mr-xs-20 mb-xs-20 animated-fade" v-animate-onscroll="'fadeInUp delay-6'" href="https://download.teamviewer.com/download/TeamViewerQS.dmg">Download for Mac</a>
              <a class="btn btn--primary icon-before icon-windows animated-fade" v-animate-onscroll="'fadeInUp delay-8'" href="https://download.teamviewer.com/download/TeamViewerQS.exe">Download for Windows</a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="contact pt-xs-60 pt-md-140 pb-xs-60 pb-md-140">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-6 col-md-4 mb-xs-40 mb-lg-0 animated-fade" v-animate-onscroll="'fadeInUp'">
            <h2>Let's talk</h2>
            <p>Ask us anything</p>

            <ul class="contact__list mt-xs-40">
              <li><span class="icon icon-before icon-mail"></span><a href="mailto:info@netvise.be">info@netvise.be</a></li>
              <li><span class="icon icon-before icon-phone"></span><a href="tel:+32 498 04 18 99">+32 16 79 17 26</a></li>
              <li><span class="icon icon-before icon-map-pin"></span><a href="#">Persilstraat 51D, 3020 Herent</a></li>
            </ul>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-8">
            <form ref="form" @submit.prevent="!sending && sendForm()" class="row">
              <div class="col-xs-12 col-md-6">
                <label class="input-text animated-fade" v-animate-onscroll="'fadeInUp delay-2'">
                  <span class="input-text__lbl">Firstname</span>
                  <input type="text" name="firstname" placeholder="Bill" v-model="firstname" required>
                </label>
              </div>
              <div class="col-xs-12 col-md-6">
                <label class="input-text animated-fade" v-animate-onscroll="'fadeInUp delay-2'">
                  <span class="input-text__lbl">Lastname</span>
                  <input type="text" name="lastname" placeholder="Gates" v-model="lastname" required>
                </label>
              </div>
              <div class="col-xs-12 mt-xs-30">
                <label class="input-text animated-fade" v-animate-onscroll="'fadeInUp delay-4'">
                  <span class="input-text__lbl">Email</span>
                  <input type="email" name="email" placeholder="bill@netvise.be" v-model="email" required>
                </label>
              </div>
              <div class="col-xs-12 mt-xs-30">
                <label class="input-text animated-fade" v-animate-onscroll="'fadeInUp delay-6'">
                  <span class="input-text__lbl">Message</span>
                  <textarea name="message" rows="8" placeholder="Hi there..." v-model="message" required></textarea>
                </label>

                <div class="mt-xs-20 animated-fade" v-animate-onscroll="'fadeInUp delay-8'">
                  <label class="input-checkbox">
                    <input type="checkbox" name="optin1" v-model="optin1">
                    <div class="input-checkbox__btn"></div>
                    <div class="input-checkbox__lbl">I agree to allow Netvise to store and process my personal data.</div>
                  </label>
                  <label class="input-checkbox">
                    <input type="checkbox" name="optin2" v-model="optin2">
                    <div class="input-checkbox__btn"></div>
                    <div class="input-checkbox__lbl">I agree to receive marketing communications from Netvise.</div>
                  </label>
                </div>

                <div class="mt-xs-30">
                  <p class="t-success icon-before icon-check" v-if="success && !sending">Your message was successfuly sent!</p>
                  <p class="t-error icon-before icon-alert-circle" v-if="error && !sending">Oops, somthing went wrong! Contact us at&nbsp;<a href="mailto:info@netvise.be">info@netvise.be</a></p>
                </div>

                <div class="mt-xs-40 animated-fade" v-animate-onscroll="'fadeInUp delay-8'">
                  <button type="submit" class="btn btn--primary icon-after icon-arrow-right" :disabled="!(firstname && lastname && email && message && optin1)" :class="{'btn--loading': sending}">{{ sending ? $t('home.contact.form.sending') : $t('home.contact.form.send') }}</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import Navbar from '@/components/shared/Navbar';
export default {
  name: "home",
  components: {
    Navbar
  },
  data() {
    return {
      page: null,
      sending: false,
      success: false,
      error: false,
      firstname: '',
      lastname: '',
      email: '',
      message: '',
      optin1: false,
      optin2: false,
      posX: 50,
      posY: 50,
      teamSwiperIndex: 0,
      clientSwiperOptions: {
        loop: true,
        direction: 'horizontal',
        allowTouchMove: false,
        slidesPerView: 5,
        spaceBetween: 30,
        autoplay: true,
        breakpoints: {
          320: {
            slidesPerView: 2
          },
          500: {
            slidesPerView: 4
          },
          991: {
            slidesPerView: 5
          }
        }
      },
      teamSwiperOptions: {
        direction: 'horizontal',
        parallax: true,
        loop: true
      }
    }
  },
  computed: {
    teamSwiper() {
      return this.$refs.teamSwiper.$swiper
    }
  },
  mounted() {
  },
  methods: {
    sendForm() {
      this.sending = true;
      const formData = {
            "fields": [
              {
                "name": "firstname",
                "value": this.firstname
              },
              {
                "name": "lastname",
                "value": this.lastname
              },
              {
                "name": "email",
                "value": this.email
              },
              {
                "name": "message",
                "value": this.message
              }
            ],
            "context": {
              "hutk": this.$cookies.get('hubspotutk'),
              "pageUri": "https://netvise.be/",
              "pageName": "Netvise website"
            },
            "legalConsentOptions":{
              "consent":{
                "consentToProcess":this.optin1,
                "text":"I agree to allow Netvise to store and process my personal data.",
                "communications":[
                  {
                    "value":this.optin2,
                    "subscriptionTypeId":999,
                    "text":"I agree to receive marketing communications from Netvise."
                  }
                ]
              }
            }
      };
      this.axios.post('https://api.hsforms.com/submissions/v3/integration/submit/20137227/8e00aff9-0e1b-498e-9f5d-f79ef46e821c', formData).then((response) => {
        this.firstname = '';
        this.lastname = '';
        this.email = '';
        this.message = '';
        this.optin1 = false;
        this.optin2 = false;
        this.sending = false;
        this.success = true;
        this.error = false;
      }).catch(function (error) {
        this.sending = false;
        this.error = true;
        this.success = false;
      });
    },
    hover(e) {
      // console.log(e);
      let btn = e.target;
      let pos = btn.getBoundingClientRect();
      this.posX = ((e.clientX - pos.left) / pos.width)*100;
      this.posY = ((e.clientY - pos.top) / pos.height)*100;
      console.log(this.posX);
    }
  }
};
</script>
