<template>
    <header class="navbar animated-fade fadeIn" :class="{'--fixed': fixed}">
      <div class="navbar__preheader">
        <div class="container no-clearfix">
          <div class="status --online" :class="checkWorkingHours() ? '--online' : '--offline'">
            <span class="status__title">Helpdesk</span>
            <ul v-if="checkWorkingHours()" class="status__dropdown">
              <li><a href="mailto:support@netvise.be"><span class="icon icon-before icon-mail"></span>support@netvise.be</a></li>
              <li><a href="tel:+32 16 79 17 26"><span class="icon icon-before icon-phone"></span>+32 16 79 17 26</a></li>
            </ul>
          </div>

          <ul class="navbar__preheader__social">
            <li><a class="icon-before icon-big icon-social-linkedin" href="https://www.linkedin.com/company/netvise" target="_blank"></a></li>
            <li><a class="icon-before icon-big icon-instagram" href="https://www.instagram.com/netvisebv" target="_blank"></a></li>
          </ul>
        </div>
      </div>

      <div class="navbar__navigation">
        <div class="container no-clearfix">
          <router-link class="navbar__logo" :to="$i18nRoute({ name: 'home'})"><img width="125" src="../../assets/netvise-logo.svg" alt="Netvise Logo"></router-link>
          <div class="navbar__toggle" v-on:click="toggle=true"><span class="icon-before icon-menu"></span></div>

          <div class="navbar__menu" :class="{'--open': toggle}">
            <div class="navbar__close" v-on:click="toggle=false"><span class="icon-before icon-x"></span></div>
            <ul class="navbar__menu__list" v-on:click="toggle=false">
              <li class="navbar__menu__list__item"><a v-scroll-to="'.intro'" :class="{'active': activeSection === 'intro'}">{{ $t('navigation.home') }}</a></li>
              <li class="navbar__menu__list__item"><a v-on:click="scrollTo('services')" :class="{'active': activeSection === 'services'}">{{ $t('navigation.services') }}</a></li>
              <li class="navbar__menu__list__item"><a v-scroll-to="'.team'" :class="{'active': activeSection === 'team' || activeSection === 'clients'}">{{ $t('navigation.about') }}</a></li>
              <li class="navbar__menu__list__item"><a v-scroll-to="'.support'" :class="{'active': activeSection === 'support'}">{{ $t('navigation.support') }}</a></li>
              <li class="navbar__menu__list__item"><a v-scroll-to="'.contact'" :class="{'active': activeSection === 'contact'}">{{ $t('navigation.contact') }}</a></li>
            </ul>

            <ul class="navbar__menu__lang">
              <li v-for="lang in supportedLanguages" :key="lang" v-on:click="changeLanguage(lang)" class="navbar__menu__lang__item" :class="{ 'active': isCurrentLanguage(lang) }">{{lang}}</li>
            </ul>
          </div>
        </div>
      </div>
    </header>
</template>

<script>
import { Trans } from '@/plugins/Translation'
export default {
  name: "Navbar",
  data() {
    return {
      toggle: false,
      fixed: false,
      activeSection: 'intro'
    }
  },
  computed: {
    supportedLanguages () {
      return Trans.supportedLanguages
    },
    currentLanguage () {
      return Trans.currentLanguage
    }
  },
  mounted() {
    this.handleScroll();
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    scrollTo(el){
      this.activeSection = el;
      this.$scrollTo('.'+el);
    },
    handleScroll() {
      let scroll = window.scrollY;
      if(scroll > 143){
        this.fixed = true;
      }else if(scroll === 0){
        this.fixed = false;
      }

      let sections = document.getElementsByTagName('section');
      for(let section of sections){
        let sectionOffset = section.offsetTop;
        let sectionHeight = section.clientHeight;
        this.activeSection === 'support' ? sectionOffset-=150 : null;
        if(scroll >= sectionOffset && scroll <= sectionOffset+sectionHeight){
          this.activeSection = section.classList[0];
        }
      }
    },
    changeLanguage (lang) {
      this.toggle = false;
      const to = this.$router.resolve({ params: { lang } })
      return Trans.changeLanguage(lang).then(() => {
        this.$router.push(to.location)
        location.reload();
      })
    },
    isCurrentLanguage (lang) {
      return lang === this.currentLanguage
    },
    checkWorkingHours() {
      const now = new Date();
      return now.getDay() <= 5 && now.getHours() >= 8 && now.getHours() < 19;
    }
  }
}
</script>
